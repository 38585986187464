import React from 'react'
import tw, { styled } from 'twin.macro'
import parse from 'html-react-parser'
import { navigate, useStaticQuery, graphql } from 'gatsby'
import { motion } from 'framer-motion'
import { useBreakpoint } from '@hooks/useBreakpoint'
import { useStateContext } from '../../context/stateContext'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const Section = tw.section`relative bg-dark max-h-screen sm:max-h-[unset] h-[550px] sm:h-[700px] xl:h-screen flex items-center overflow-hidden`
const Gradient = tw.div`absolute left-0 right-0 bottom-0 h-1/2 w-full`
const Wrap = tw.div`relative max-w-screen-2xl mx-auto px-5 w-full`
const Content = styled(motion.div)`
	${tw`flex flex-col items-end justify-center w-full gap-6 pb-10 my-8 pointer-events-none md:pb-0`}
	perspective: 1000px;
	@media (max-width: 768px) {
		perspective: 400px;
	}
`
const Headline = styled(motion.div)`
	${tw`relative text-6xl text-white uppercase cursor-pointer xxs:text-7xl xxs:leading-075 leading-075 sm:text-9xl sm:leading-075 font-futuraExtra xl:text-200px xl:leading-075 xs:tracking-3 2xl:text-[240px] whitespace-nowrap`}/* transform-style: preserve-3d; */
`
const Text = tw.p`inline-flex font-futuraBold tracking-normal absolute top-0 -left-1 lg:-left-5 -translate-x-full text-10px sm:text-xs lg:text-3xl leading-none text-right`

const IntroHeader = ({ locale }) => {
	const [initialAnimation, setInitialAnimation] = React.useState(true)
	const [animation, setAnimation] = React.useState(false)
	const [isNavigating, setIsNavigating] = React.useState(false)
	const [siteOffset, setSiteOffset] = React.useState(null)
	const { language } = useStateContext()

	const introHeaderData = useStaticQuery(graphql`
		{
			wp {
				prototyprOptions {
					optionsIntroHeader {
						introBig: backgroundImage {
							localFile {
								childImageSharp {
									gatsbyImageData(width: 1800, quality: 70, formats: [AUTO, WEBP], placeholder: NONE)
								}
							}
							altText
						}
						introSmall: backgroundImage {
							localFile {
								childImageSharp {
									gatsbyImageData(height: 550, quality: 75, formats: [AUTO, WEBP], placeholder: NONE)
								}
							}
							altText
						}
						backgroundImageMobile {
							localFile {
								childImageSharp {
									gatsbyImageData(height: 550, quality: 75, formats: [AUTO, WEBP], placeholder: NONE)
								}
							}
							altText
						}
						items {
							headline
							text
							externalLink
							externalUrl
							url {
								... on WpPage {
									id
									uri
									slug
									title
									translated {
										slug
										uri
										title
										id
									}
								}
							}
						}
					}
				}
			}
		}
	`)

	const headerVariants = {
		beforeset: {
			y: '-140%',
		},
		init: {
			y: 0,
			transition: {
				type: 'tween',
				ease: [0.87, 0, 0.13, 1],
				duration: 1.5,
			},
		},
		animate: {
			transition: {
				duration: 0.3,
				staggerChildren: 1,
			},
		},
		exit: {
			transition: {
				duration: 0.3,
				staggerChildren: 1,
			},
		},
	}
	const menuItemVariants = {
		beforeset: {
			originX: 1,
			rotateY: -80,
			pointerEvents: 'none',
		},
		init: (index, aniState) => ({
			y: 0,
			originX: 1,
			rotateY: -45,
			pointerEvents: 'all',
			transition: {
				type: 'spring',
				// duration: 0.35,
				delay: aniState ? 1.4 + -index * 0.15 : 0.35,
			},
		}),
		initStatic: (index) => ({
			y: 0,
			originX: 1,
			rotateY: -45,
			pointerEvents: 'all',
			transition: {
				type: 'tween',
				duration: 0.3,
			},
		}),
		hover: {
			rotateY: -35,
			originX: 1,
			color: '#003899',
			y: 0,
			transition: {
				type: 'tween',
				duration: 0.3,
			},
		},
		active: {
			rotateY: 0.01,
			originX: 1,
			scale: 1,
			x: 0,
			y: [0, 0, -100],
			transition: {
				type: 'tween',
				duration: 1,
				times: [0, 0.6, 1],
			},
		},
		notActiveExit: (index) => ({
			rotateY: -123,
			originX: 1,
			opacity: 0,
			transition: {
				type: 'tween',
				duration: 0.3,
				delay: index * 0.2,
			},
		}),
	}
	const handleSiteOffset = () => {
		setSiteOffset(window.pageYOffset)
	}
	React.useEffect(() => {
		window.addEventListener('scroll', handleSiteOffset)
		return () => window.removeEventListener('scroll', handleSiteOffset)
	})
	const { isMobile } = useBreakpoint()
	const imageBig = introHeaderData.wp.prototyprOptions.optionsIntroHeader.introBig
	const imageSmall = introHeaderData.wp.prototyprOptions.optionsIntroHeader.introSmall
	const mobImage = introHeaderData.wp.prototyprOptions.optionsIntroHeader.backgroundImageMobile

	return (
		<Section>
			<GatsbyImage
				image={getImage(isMobile ? (mobImage ? mobImage.localFile : imageSmall.localFile) : imageBig.localFile)}
				alt={(isMobile ? (mobImage ? mobImage.altText : imageSmall.altText) : imageBig.altText) || ''}
				loading='eager'
				className='absolute inset-0 w-full h-full'
			/>
			{/* <BackgroundImage style={{ backgroundImage: `url(${isMobile ? (mobImage ? mobImage : imageSmall) : image})`, backgroundPositionY: `calc(50% - ${siteOffset / 5}px)` }} /> */}
			<Gradient style={{ backgroundImage: `linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,${siteOffset / 100}) calc(100% - ${siteOffset / 10}%))` }} />
			<Wrap style={{ bottom: siteOffset / 2 }}>
				<Content variants={headerVariants} initial='beforeset' onAnimationComplete={() => setInitialAnimation(false)} animate='init' exit='exit'>
					{introHeaderData.wp.prototyprOptions.optionsIntroHeader.items.map((item, index) => {
						const isActive = index === animation
						const itemUrlObject = language === 'de' ? item.url : item.url?.translated[0]
						return (
							<Headline
								key={`intro-head-${index}`}
								custom={index}
								variants={menuItemVariants}
								initial={initialAnimation ? 'beforeset' : 'init'}
								whileHover={!isActive && !initialAnimation && menuItemVariants.hover}
								animate={isActive ? 'active' : animation && !isActive ? 'notActiveExit' : initialAnimation ? 'init' : 'initStatic'}
								exit={!isActive && isNavigating && 'notActiveExit'}
								onClick={(e) => {
									if (item.externalLink) {
										return
									}
									setAnimation(isActive ? false : index)
									setIsNavigating(true)
									navigate(itemUrlObject.uri)
								}}
							>
								{item.externalLink && (
									<a href={item.externalUrl} rel='noreferrer noopener' target='_blank'>
										{item.headline}
									</a>
								)}
								{!item.externalLink && <div>{language === 'de' ? item.headline : itemUrlObject.title}</div>}
								{item.text && <Text>{parse(item.text)}</Text>}
							</Headline>
						)
					})}
				</Content>
			</Wrap>
		</Section>
	)
}
export default IntroHeader
