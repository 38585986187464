import React from 'react'
import { graphql } from 'gatsby'
import { useGTM } from '@hooks/useGTM'
import loadable from '@loadable/component'
import IntroHeader from '@root/IntroHeader'
import { replaceWithCorrectTranslation } from '../helpers/replaceWithCorrectTranslation'

export default function PageTemplate({ data, path }) {
	const page = data.main
	const { locale } = page.locale
	const blocksJSON = JSON.parse(page.blocksJSON)
	useGTM({ locale, path, pageType: page.pageOptionsPageType.pageType })
	const Foot = () => {
		const Footer = loadable(() => import('@root/Footer'))
		return <Footer />
	}
	const LazyNavBar = () => {
		const NavBar = loadable(() => import('@root/NavBar'))
		return <NavBar />
	}
	const LazySEO = () => {
		const SEO = loadable(() => import('@root/Seo'))
		return <SEO seoData={page.seo} />
	}

	const BContent = () => {
		const BasicContent = loadable(() => import('@modules/BasicContent'))
		return <BasicContent data={{ content: page.content, title: page.title }} locale={locale} />
	}
	const isSSR = typeof window === 'undefined'
	return (
		<>
			<LazyNavBar />
			<LazySEO />
			{(page.isFrontPage || page.uri === '/en/emil-frey-racing/') && <IntroHeader />}
			{page.template?.templateName !== 'Basic Template' &&
				page.blocks.map((module, index) => {
					if (module.__typename.includes('WpCore')) return
					replaceWithCorrectTranslation(module, blocksJSON)
					const LoadableModuleBlock = loadable(() => import(`@modules/${module.__typename}`))
					return <LoadableModuleBlock data={module} key={`${module.__typename}-${index}`} locale={locale} overline={page.title} />
				})}
			{page.template?.templateName === 'Basic Template' && <BContent />}
			<Foot />
		</>
	)
}

export const query = graphql`
	query ($page: String) {
		main: wpPage(id: { eq: $page }) {
			locale {
				locale
			}
			title
			id
			uri
			template {
				templateName
			}
			content
			isFrontPage
			blocks {
				__typename
				...AllBlocks
			}
			blocksJSON
			seo {
				metaDesc
				title
				opengraphImage {
					localFile {
						publicURL
					}
				}
				schema {
					pageType
				}
			}
			pageOptionsPageType {
				pageType
			}
		}
	}
`
